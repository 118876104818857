<template>
  <div>
    <h1>Kingisaajad</h1>

    <h2>Aasta kaupa</h2>
    <table v-for="(resultItemArr, year) in resultsByYear" :key="year" class="table">
      <thead>
        <th colspan="3">{{ year }}</th>
      </thead>
      <tbody>
        <tr v-for="(resultItem, i) in resultItemArr" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ resultItem.gifter }}</td>
          <td>{{ resultItem.giftee }}</td>
        </tr>
      </tbody>
    </table>

    <h2>Loosija kaupa</h2>
    <table v-for="(resultItemArr, gifterName) in resultsByGifter" :key="gifterName" class="table">
      <thead>
        <th colspan="3">{{ gifterName }}</th>
      </thead>
      <tbody>
        <tr v-for="(resultItem, i) in resultItemArr" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ resultItem.year }}</td>
          <td>{{ resultItem.giftee }}</td>
        </tr>
      </tbody>
    </table>

    <button class="btn btn-primary" @click="generateGifterPairs">
      Loo aasta {{ new Date().getFullYear() }} paarid
    </button>
  </div>
</template>

<script>
export default {
  data: () => ({
    resultsByGifter: {},
    resultsByYear: {},
  }),

  methods: {
    getData() {
      this.$http.get(this.api.$getGifterPairs).then((res) => {
        this.resultsByGifter = res.data.resultsByGifter;
        this.resultsByYear = res.data.resultsByYear;
      });
    },

    generateGifterPairs() {
      if (!confirm('KINDEL, ET SA POLE SEDA JUBA TEINUD?')) {
        return false;
      }

      if (!confirm('NAGU - PÄRIS-PÄRIS KINDEL?')) {
        return false;
      }

      if (!confirm('Here goes...')) {
        return false;
      }

      this.$http
        .get(this.api.$generateGifterPairs)
        .then((res) => {
          console.log("");
          console.log("");
          console.log("");
          console.log("============ Done generating pairs =====================");
          console.log(res.data);
          console.log("=================================");
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
