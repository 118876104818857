<template>
  <nav class="navbar navbar-expand-lg main-nav">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link :to="{ name: 'home' }" class="nav-link">Loosimine</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'gift-wish' }" class="nav-link"
              >Minu kingisoov</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'previous-results' }" class="nav-link"
              >Eelnevad aastad</router-link
            >
          </li>
        </ul>
        <div class="main-nav__right">
          <div v-if="$store.getters.user" class="main-nav__user">
            <i class="fal fa-user-circle"></i> {{ $store.getters.user.firstName }}
          </div>
          <logout />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Logout from "../components/Auth/Logout";

export default {
  components: { Logout },
};
</script>

<style lang="scss">
.main-nav {
  padding: 20px 55px 0 50px;

  @media (max-width: 500px) {
    padding: 5px;
    background-color: darken($bg-color, 5%);
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 100px;
    font-size: 20px;

    @media (max-width: 1000px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  .navbar-collapse {
    justify-content: space-between;
    align-items: center;
  }

  .nav-link {
    position: relative;
    margin-right: 15px;
    padding: 10px 15px;
    font-size: 20px;
    color: $text-color;
    user-select: none;
    transition: all 90ms ease-in-out;

    &::after {
      content: "";
      position: absolute;
      opacity: 0;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100%;
      background: $text-color;
      transition: all 90ms ease-in-out;
    }

    &:hover,
    &:focus {
      color: darken($text-color, 15%);
      outline: none;

      &::after {
        opacity: 1;
      }
    }

    &.router-link-exact-active {
      color: $accent-color;

      &::after {
        opacity: 1;
        background: $accent-color;
      }
    }

    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  &__user {
    margin: 0 30px 0 0;
    color: lighten($text-color, 50%);
    line-height: initial;

    i {
      position: relative;
      top: 2px;
      margin-right: 6px;
      font-size: 24px;
    }

    @media (max-width: 500px) {
      font-size: 14px;

      i {
        display: none;
      }
    }
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-toggler[aria-expanded="true"] {
    border: 1px solid $text-color;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .collapse,
  .collapsing {
    margin-top: 15px;

    .nav-link {
      padding-left: 0;
    }
  }
}
</style>
