import Vue from 'vue'
import App from './App.vue'

import store from './store'
import config from './config'
import { router } from './router'
import http from './http'

import 'bootstrap';
import moment from 'moment';
import vSelect from 'vue-select';

import GuestLayout from './layouts/GuestLayout';
import UserLayout from './layouts/UserLayout';
import AdminLayout from './layouts/AdminLayout';

Vue.config.productionTip = false;

Vue.filter('formatDateAndTime', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM') +
      ' kell ' + moment(String(value)).format('HH.mm');
  }

  return '';
});

Vue.prototype.appConfig = config;
Vue.prototype.api = config.$apiEndpoints;
Vue.prototype.$store = store;
Vue.prototype.$http = http();

vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('i', { class: 'fal fa-times' }),
  },
  OpenIndicator: {
    render: createElement => createElement('i', { class: 'fal fa-chevron-down' }),
  },
});

Vue.component('v-select', vSelect);

Vue.component('guest-layout', GuestLayout)
Vue.component('user-layout', UserLayout)
Vue.component('admin-layout', AdminLayout)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
