<template>
  <div class="app guest">
    <div class="guest__loader" ref="loader" v-if="isLoadingAnimationRunning">
      <i class="fal fa-gift"></i>
    </div>

    <div class="row guest__container" ref="container">
      <div class="col-lg-5 guest__col guest__col--1">
        <div class="guest__content">
          <h1 class="guest__title">
            Jõululoos<span class="guest__title__accent">{{ year }}</span>
          </h1>
          <login />
        </div>
      </div>

      <div class="col-lg-7 background-img" :class="'background-img--' + background">
        <!-- # -->
      </div>
    </div>
  </div>
</template>

<script>
import anime from "animejs";
import Login from "../components/Auth/Login";

export default {
  components: { Login },

  data: () => ({
    isLoadingAnimationRunning: true,
    year: new Date().getFullYear(),
  }),

  methods: {
    showContent() {
      const self = this;

      anime({
        targets: this.$refs.loader,
        loop: true,
        rotate: 360,
        scale: [1, 1.1, 1],
        duration: 1000,
        easing: "linear"
      });

      anime({
        targets: this.$refs.loader,
        opacity: 0,
        duration: 400,
        delay: 1500,
        easing: "easeInOutQuart",
        complete: function () {
          self.isLoadingAnimationRunning = false;
        },
      });

      anime({
        targets: this.$refs.container,
        opacity: 1,
        delay: 1800,
        duration: 600,
        easing: "cubicBezier(.5, .05, .1, .3)",
      });
    },
  },

  mounted() {
    this.showContent();
  },

  computed: {
    /**
     * Selects a random image from 10 images.
     */
    background() {
      const min = 1;
      const max = 10;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>

<style lang="scss">
.guest {
  position: relative;
  overflow-x: hidden;

  &__loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 64px;
      color: $accent-color;
    }
  }

  &__container {
    opacity: 0;
  }

  &__content {
    width: 45%;

    @media (max-width: 600px) {
      width: 80%;
    }
  }

  &__col {
    overflow: hidden;

    &--1 {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
    }
  }

  &__title {
    margin-bottom: 60px;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: $font-weight-regular;
    text-align: center;

    &__accent {
      font-size: 32px;
      font-weight: $font-weight-extra-light;
    }
  }
}
</style>
