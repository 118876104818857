<template>
  <div class="gift-wish">
    <h1>
      Minu kingisoov <span class="accent">{{ year }}</span>
    </h1>

    <i class="spinner spinner--big fal fa-spinner" v-if="isLoadingData"></i>

    <form @submit.prevent="save" v-else>
      <div class="form-group">
        <textarea
          class="form-control"
          maxlength="500"
          v-model="giftWish"
          rows="4"
          placeholder="Trüki siia..."
        ></textarea>
      </div>

      <div class="gift-wish__footer">
        <div>
          <div class="gift-wish__success" v-show="!!successMessage">
            <i class="fal fa-check-circle"></i> {{ successMessage }}
          </div>
          <div class="gift-wish__error" v-show="!!errorMessage">
            <i class="fal fa-exclamation-circle"></i> {{ errorMessage }}
          </div>
        </div>

        <button
          class="btn btn-primary gift-wish__submit"
          type="submit"
          :disabled="isProcessingForm"
        >
          <span v-if="!isProcessingForm">Salvesta minu kingisoov </span>
          <span v-else><i class="spinner fal fa-spinner"></i> Palun oota...</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data: () => ({
    year: new Date().getFullYear(),
    isLoadingData: true,
    isProcessingForm: false,
    giftWish: "",
    successMessage: "",
    errorMessage: "",
  }),

  methods: {
    getData() {
      let url = this.api.$getMyGiftWish.replace("{year}", this.year);

      this.$http
        .get(url)
        .then((res) => {
          this.giftWish = res.data.wish;
        })
        .catch((e) => {
          this.errorMessage = "Kingisoovi ei õnnestunud laadida!";
          console.log(e.response);
        })
        .finally(() => (this.isLoadingData = false));
    },

    save() {
      this.successMessage = "";
      this.errorMessage = "";

      this.isProcessingForm = true;

      this.$http
        .post(this.api.$setMyGiftWish, { wish: this.giftWish, year: this.year })
        .then((res) => {
          this.giftWish = res.data.wish;

          this.successMessage = "Kingisoov salvestati!";
        })
        .catch((e) => {
          this.errorMessage = "Kingisoovi ei õnnestunud salvestada!";
          console.log(e.response);
        })
        .finally(() => (this.isProcessingForm = false));
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss">
.gift-wish {
  textarea {
    resize: none;
    font-size: 18px;

    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  &__footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 15px;

    @media (max-width: 750px) {
      flex-direction: column;
    }
  }

  .form-text {
    font-weight: $font-weight-regular;
  }

  button {
    .spinner {
      margin-right: 8px;
    }
  }

  &__footer {
    position: relative;
  }

  &__success,
  &__error {
    i {
      position: relative;
      top: 4px;
      margin-right: 4px;
      font-size: 24px;
    }

    @media (max-width: 750px) {
      margin-bottom: 10px;
    }
  }

  &__success {
    color: $secondary-color;
  }

  &__error {
    color: $accent-color;
  }
}
</style>
