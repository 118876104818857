<template>
  <div class="giftee">
    <p class="giftee__intro">Sinu kingi saab endale:</p>

    <div class="giftee__name">{{ giftee }}</div>
    <!-- <p class="giftee__drawn-at">Loosisid {{ drawnAt | formatDateAndTime }}</p> -->

    <div class="giftee__wish">
      <p v-if="wish">
        {{ giftee }} tahab jõuludeks saada: <br />
        <span class="accent">{{ wish }}</span>
      </p>
      <p v-else>{{ giftee }} ei ole veel oma kingisoovi kirja pannud.</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["giftee", "wish", "drawnAt"],

  data: () => ({}),
};
</script>

<style lang="scss">
.giftee {
  &__intro {
    margin-bottom: 10px;
  }

  &__name {
    font-size: 50px;
    color: $accent-color;
    text-transform: uppercase;

    @media (max-width: 500px) {
      font-size: 30px;
    }
  }

  p {
    font-size: 18px;

    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  &__drawn-at {
    color: lighten($text-color, 50%);
  }

  &__wish {
    .accent {
      display: block;
      max-width: 750px;
      margin: 0 auto;
      margin-top: 15px;
      color: $accent-color;
    }
  }
}
</style>
