<template>
  <div class="slider">
    <div class="slider__wrapper">
      <div class="slider__slides" ref="container">
        <div
          class="slider__slide"
          v-for="(slide, i) in displaySlides"
          :key="i"
          ref="slides"
        >
          {{ slide }}
        </div>
      </div>
    </div>
    <button
      class="btn btn-primary slider__start"
      @click="startSlider"
      v-if="!isSpinning && !isFinished"
    >
      Vajuta siia!
    </button>
  </div>
</template>

<script>
export default {
  props: ["slides", "result"],
  data: () => ({
    isSpinning: false,
    isFinished: false,
    startingPosition: null,
    containerElement: null,
    slideElements: [],
    slideCount: 0,
    slideHeight: 0,
    currentSlideIndex: 0,
    allowShift: true,
    speed: 30, // The smaller the number, the faster the wheel will spin
    speedStep: 7, // The bigger the number, the sooner the wheel will slow

    resultIndex: null,
    stopOffset: null,
    currentSpinNumber: 1,
    maxSpins: 3,
  }),

  methods: {
    startSlider() {
      this.isSpinning = true;
      this.spinSlider();
    },

    spinSlider() {
      if (!this.isSpinning) {
        return;
      }

      setTimeout(() => {
        if (this.currentSpinNumber >= this.maxSpins) {
          this.setStopOffset();
        }

        this.shiftSlide();

        // Slow speed
        this.speed += this.speedStep;

        this.spinSlider();
      }, this.speed);
    },

    setStopOffset() {
      if (this.stopOffset === null) {
        this.stopOffset = (this.resultIndex === 0)
          ? this.slideCount * this.slideHeight * -1
          : this.resultIndex * this.slideHeight * -1;
      }
    },

    stopSlider() {
      this.isSpinning = false;
      this.isFinished = true;
      this.$emit("stopped");
    },

    shiftSlide() {
      this.containerElement.style.transition = "top " + (this.speed - 25) + "ms";

      if (this.allowShift) {
        this.startingPosition = this.containerElement.offsetTop;
        this.containerElement.style.top = this.startingPosition - this.slideHeight + "px";
        this.currentSlideIndex++;
      }

      this.allowShift = false;

      // If stopOffset is set,
      // stop sliding at said offset if we've reached it
      if (this.stopOffset !== null) {
        if (this.startingPosition === this.stopOffset) {
          this.stopSlider();
        } else {
          // Start slowing if a few people away
          let resultOffset = (this.startingPosition - this.stopOffset) / this.slideHeight;
          if (resultOffset <= 7) {
            this.speedStep += 30;
          }
        }
      }
    },

    checkIndex() {
      this.containerElement.style.transition = "none";

      if (this.currentSlideIndex == -1) {
        this.containerElement.style.top = -(this.slideCount * this.slideHeight) + "px";
        this.currentSlideIndex = this.slideCount - 1;
      }

      if (this.currentSlideIndex == this.slideCount) {
        this.containerElement.style.top = -(1 * this.slideHeight) + "px";
        this.currentSlideIndex = 0;
        this.currentSpinNumber++;
      }

      this.allowShift = true;
    },
  },

  mounted() {
    this.containerElement = this.$refs.container;
    this.containerElement.addEventListener("transitionend", this.checkIndex);

    this.slideElements = this.$refs.slides;

    this.slideCount = this.slides.length;
    this.slideHeight = this.slideElements[0].offsetHeight;

    this.resultIndex = this.slides.indexOf(this.result);

    // Two spins is enough if chosen person
    // is among the bottom half of the participants
    if (this.resultIndex > this.slideCount / 2) {
      this.maxSpins--;
    }
  },

  computed: {
    displaySlides() {
      let displaySlides = [...this.slides];

      const lastSlide = this.slides[this.slides.length - 1];

      // Prepend last slide to beginning
      displaySlides.unshift(lastSlide);

      // Append first slide to end
      displaySlides.push(this.slides[0]);

      return displaySlides;
    },
  },
};
</script>

<style lang="scss">
$slider-width: 600px;
$slider-height: 100px;
$slider-font-size: 50px;
$slider-box-shadow: inset 0 5px 12px rgba(0, 0, 0, 0.2),
  inset 0 -5px 12px rgba(0, 0, 0, 0.2);

.slider {
  position: relative;
  width: 90%;
  max-width: $slider-width;

  @media (max-width: 750px) {
    max-width: 90%;
  }

  &__wrapper {
    position: relative;
    height: $slider-height;
    border: 1px solid $border-color;
    overflow: hidden;
    box-shadow: $slider-box-shadow;
    background: $accent-color;
    color: $bg-color;
    text-transform: uppercase;

    @media (max-width: 500px) {
      height: 60px;
    }
  }

  &__slides {
    position: relative;
    left: 0;
    top: -$slider-height;
    height: 10000px;
    font-size: $slider-font-size;

    @media (max-width: 600px) {
      font-size: 40px;
    }

    @media (max-width: 500px) {
      font-size: 20px;
      top: -60px;
    }
  }

  &__slide {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: $slider-height;

    @media (max-width: 500px) {
      height: 60px;
    }
  }

  &__start {
    position: absolute;
    top: 0;
    left: 0;
    height: $slider-height;
    width: 100%;
    font-size: $slider-font-size;
    text-transform: uppercase;
    line-height: 95px;
    box-shadow: $slider-box-shadow;

    @media (max-width: 600px) {
      font-size: 40px;
    }

    @media (max-width: 500px) {
      font-size: 20px;
      line-height: 50px;
      height: 60px;
    }
  }
}
</style>
