import Vue from "vue";
import VueRouter from "vue-router";

import store from "./store.js"

import Login from "./components/Auth/Login";
import ChristmasDraw from "./components/ChristmasDraw/Draw";
import GiftWish from "./components/GiftWish/GiftWish";
import PreviousResults from "./components/PreviousResults/PreviousResults";
import GifterPairs from "./components/ChristmasDraw/Admin/GifterPairs";

Vue.use(VueRouter);

const loginPath = "/login";

export const router = new VueRouter({
    mode: "history",
    routes: [
        // User routes
        {
            name: "home",
            path: "/",
            component: ChristmasDraw,
            meta: {
                title: "Loosimine",
                layout: "user",
                allowedRoles: ["user", "admin"]
            }
        },
        {
            name: "gift-wish",
            path: "/wish",
            component: GiftWish,
            meta: {
                title: "Minu kingisoov",
                layout: "user",
                allowedRoles: ["user", "admin"]
            }
        },
        {
            name: "previous-results",
            path: "/previous",
            component: PreviousResults,
            meta: {
                title: "Eelmised aastad",
                layout: "user",
                allowedRoles: ["user", "admin"]
            }
        },

        // Admin routes
        {
            name: "admin.gifters",
            path: "/admin/gifters",
            component: GifterPairs,
            meta: {
                title: "Loosijad ja loositud",
                layout: "admin",
                allowedRoles: ["admin"]
            }
        },

        // Guest routes
        {
            name: "login",
            path: loginPath,
            component: Login,
            meta: { layout: "guest" }
        },
    ]
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || "Jõululoos";

    const allowedRoles = to.meta.allowedRoles || [];
    const isAuthRequired = allowedRoles.length > 0;

    const isLoggedIn = store.getters.isLoggedIn;
    const user = store.getters.user || {};

    if (isLoggedIn) {
        // If authed user visits login, redirect home
        if (to.path === loginPath && isLoggedIn) {
            return next({ name: "home" });
        }

        // If user does not have required role, redirect home
        if (!allowedRoles.includes(user.role)) {
            return next("/");
        }
    } else {
        if (isAuthRequired) {
            return next({ name: "login" });
        }
    }

    next();
});
