<template>
  <div class="app app--user">
    <div class="app--user__bg background-img" :class="'background-img--' + background">
      <!-- # -->
    </div>
    <div class="app--user__content">
      <user-nav></user-nav>

      <div class="app--user__main">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import UserNav from "./UserNav";

export default {
  components: { UserNav },

  computed: {
    /**
     * Selects a random image from 10 images.
     */
    background() {
      const min = 1;
      const max = 10;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>

<style lang="scss">
.app--user {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100vh;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: -5;
  }

  &__content {
    max-width: 1000px;
    min-height: 500px;
    background: $bg-color;

    @media (max-width: 1000px) {
      width: 90%;
    }
  }

  &__main {
    padding: 55px;
    text-align: center;

    @media (max-width: 500px) {
      padding: 15px;
    }
  }
}
</style>
