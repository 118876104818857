<template>
  <div class="previous-results">
    <h1>Minu varasemad loositulemused</h1>

    <i class="spinner spinner--big fal fa-spinner" v-if="isLoadingData"></i>

    <p v-else-if="!errorMessage && !giftees.length">Eelnevate aastate tulemusi ei ole.</p>

    <table class="table" v-else>
      <thead>
        <th>Aasta</th>
        <th>Kingisaaja</th>
      </thead>

      <tbody>
        <tr v-for="giftee in giftees" :key="giftee.year">
          <td>{{ giftee.year }}</td>
          <td>{{ giftee.firstName }}</td>
        </tr>
      </tbody>
    </table>

    <p class="error">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  data: () => ({
    giftees: [],
    isLoadingData: true,
    errorMessage: "",
  }),

  methods: {
    getData() {
      this.$http
        .get(this.api.$getMyPreviousResults)
        .then((res) => {
          this.giftees = res.data;
        })
        .catch((e) => {
          this.errorMessage = "Eelnevate aastate loositulemusi ei õnnestunud laadida!";
          console.log(e);
        })
        .finally(() => (this.isLoadingData = false));
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
table {
  max-width: 425px;
  margin: 0 auto;

  @media (max-width: 500px) {
    font-size: 14px;
  }
}
</style>
