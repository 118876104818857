import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import config from "./config";
import jwt_decode from "jwt-decode";

Vue.use(Vuex);

function getToken() {
    return localStorage.getItem("token") || null;
}

function parseToken(token) {
    return jwt_decode(token);
}

function getUser() {
    const token = getToken();

    if (token) {
        return parseToken(token).user;
    }

    return null;
}

export default new Vuex.Store({
    state: {
        status: "",
        token: getToken(),
        user: getUser()
    },

    mutations: {
        authRequest(state) {
            state.status = "loading";
        },
        authSuccess(state, payload) {
            state.status = "success";
            state.user = getUser();
            state.token = payload.token;
        },
        authError(state) {
            state.status = "error";
        },
        logout(state) {
            state.status = "";
            state.token = null;
            state.user = null;
        },
    },

    actions: {
        login({ commit }, loginModel) {
            return new Promise((resolve, reject) => {
                commit("authRequest")
                axios({
                    url: config.$apiUrl + config.$apiEndpoints.$login,
                    data: loginModel,
                    method: "POST"
                })
                    .then(res => {
                        const token = res.data.access_token;
                        localStorage.setItem("token", token);
                        commit("authSuccess", { token: token });
                        resolve(res);
                    })
                    .catch(err => {
                        commit("authError");
                        localStorage.removeItem("token");
                        reject(err);
                    })
            })
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit("logout");
                localStorage.removeItem("token");
                resolve();
            })
        }
    },

    getters: {
        token: state => state.token,
        user: state => state.user,
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
    }
});
