export default {
  $isProduction: process.env.NODE_ENV === "production",
  $apiUrl: process.env.VUE_APP_API_URL,
  $apiEndpoints: {
    // Auth
    $login: "/auth/login",

    // Christmas draw
    $participants: "/draw/participants",
    $draw: "/draw/draw",
    $registerDraw: "/draw/register/{id}",
    $getGifterPairs: "/draw/pairs",
    $generateGifterPairs: "/draw/generate",

    // Gift wish
    $setMyGiftWish: "/gift-wish/set",
    $getMyGiftWish: "/gift-wish/get/{year}",

    // Previous results
    $getMyPreviousResults: "/previous/list"
  }
};
