<template>
  <div class="login">
    <form @submit.prevent="login" class="login__form">
      <div class="login__error form-text">
        {{ errorMsg }}
      </div>

      <div class="mb-3">
        <v-select
          :options="participants"
          v-model="loginModel.email"
          :placeholder="'Mina olen...'"
          autocomplete="off"
        >
          <template #no-options>Loosijat ei leitud.</template>
        </v-select>
      </div>

      <div class="mb-3">
        <input
          type="password"
          id="password"
          placeholder="Salasõna"
          v-model="loginModel.password"
          class="form-control"
        />
      </div>

      <button
        class="btn btn-primary login__submit"
        type="submit"
        :disabled="isSubmitDisabled"
      >
        <span v-if="!isProcessingLogin"
          >Logi sisse
          <i class="fal fa-chevron-right"></i>
        </span>
        <i v-else class="spinner fal fa-spinner"></i>
      </button>
    </form>
  </div>
</template>

<script>
export default {
  data: () => ({
    participants: [],
    loginModel: {
      email: "",
      password: "",
    },
    isLoadingParticipants: true,
    isProcessingLogin: false,
    errorMsg: "",
  }),
  methods: {
    /**
     * Fetches a list of all people
     * participanting in the christmas draw.
     */
    getParticipants() {
      this.isLoadingParticipants = true;

      this.$http
        .get(this.api.$participants)
        .then((res) => {
          this.participants = res.data.map((x) => {
            return { id: x.email, label: x.first_name };
          });
        })
        .catch((e) => {
          this.errorMsg = "Esines viga: loosis osalejaid ei õnnestunud laadida.";
          console.log(e.response);
        })
        .finally(() => (this.isLoadingParticipants = false));
    },

    /**
     * Starts the authentication process.
     */
    login() {
      this.errorMsg = "";
      this.isProcessingLogin = true;

      this.$store
        .dispatch("login", {
          email: this.loginModel.email.id,
          password: this.loginModel.password,
        })
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch((e) => {
          const res = e.response ? e.response.data : {};
          this.errorMsg = res.message || "Salasõna ei olnud õige.";
        })
        .finally(() => (this.isProcessingLogin = false));
    },
  },

  computed: {
    isSubmitDisabled() {
      return (
        this.isProcessingLogin || !this.loginModel.email || !this.loginModel.password
      );
    },
  },

  mounted() {
    this.getParticipants();
  },
};
</script>

<style lang="scss">
.login {
  &__submit {
    float: right;

    i {
      margin-left: 7px;
      font-size: 13px;
    }

    .spinner {
      margin: 0;
    }
  }

  &__form {
    position: relative;
  }

  &__error {
    position: absolute;
    top: -30px;
    left: 0;
    color: $accent-color;
  }
}
</style>
