<template>
  <div id="app">
    <component :is="layout"></component>
  </div>
</template>

<script>
const defaultLayout = "guest";

export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },
  created() {
    let self = this;

    // Check for expired JWT
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.response && err.response.status === 401) {
          self.$store.dispatch("logout").then(() => self.$router.push({ name: "login" }));
        }

        throw err;
      });
    });
  },
};
</script>

<style lang="scss">
@import "./assets/scss/app.scss";

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  color: $text-color;
}

.background-img {
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;

  @for $i from 1 through 10 {
    &--#{$i} {
      @include bg-image($i);
    }
  }
}

h1 {
  margin: 10px 0 35px 0;
  text-transform: uppercase;
  font-size: 26px;
  line-height: initial;

  .accent {
    font-size: 26px;
    font-weight: $font-weight-light;
  }

  @media (max-width: 500px) {
    font-size: 16px;

    .accent {
      font-size: 18px;
    }
  }
}

button {
  .spinner {
    position: relative;
    top: 2px;
  }
}

.error {
  color: $accent-color;
}
</style>
