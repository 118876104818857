<template>
  <button class="btn btn-light logout" @click="logout">Logi välja</button>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<style lang="scss">
.logout {
  font-size: 20px;

  @media (max-width: 500px) {
    font-size: 14px;
  }
}
</style>
