<template>
  <div class="draw">
    <h1>
      Jõululoos <span class="accent">{{ year }}</span>
    </h1>

    <i class="spinner spinner--big fal fa-spinner" v-if="isLoadingData"></i>

    <div v-else-if="!errorMessage && drawId">
      <div class="giftee__container" v-if="drawnAt">
        <giftee :giftee="giftee" :wish="gifteeWish" :drawnAt="drawnAt"></giftee>
      </div>

      <div class="draw__slider" v-else>
        <slider :slides="participants" :result="giftee" @stopped="onDrawFinished" />
      </div>
    </div>

    <div v-else-if="!errorMessage && !drawId" class="draw__uninitialized">
      Aasta {{ year }} loosimine veel ei käi. :(
    </div>

    <div class="error">{{ errorMessage }}</div>
  </div>
</template>

<script>
import Slider from "./Slider";
import Giftee from "./Giftee";

export default {
  components: { Slider, Giftee },

  data: () => ({
    year: new Date().getFullYear(),
    drawId: null,
    participants: [],
    giftee: "",
    gifteeWish: "",
    drawnAt: null,
    isLoadingData: true,
    errorMessage: "",
  }),

  methods: {
    getDrawData() {
      this.$http
        .get(this.api.$draw)
        .then((res) => {
          const data = res.data;

          this.drawnAt = data.drawn_at;
          this.drawId = data.draw_id;
          this.giftee = data.giftee;
          this.participants = data.participants;
          this.gifteeWish = data.wish;
        })
        .catch((e) => {
          this.errorMessage = "Loosimise alustamisel esines viga!";
          console.log("Draw load error:");
          console.log(e.response);
        })
        .finally(() => (this.isLoadingData = false));
    },

    onDrawFinished() {
      this.$http
        .post(this.api.$registerDraw.replace("{id}", this.drawId))
        .then((res) => {
          this.giftee = res.data.giftee;
          this.gifteeWish = res.data.wish;

          // Wait a few seconds before switching views
          setTimeout(() => {
            this.drawnAt = res.data.drawn_at;
          }, 2000);
        })
        .catch((e) => {
          this.errorMessage = "Tulemuse salvestamisel esines viga!";
          console.log("Draw register error:");
          console.log(e.response);
        });
    },
  },

  mounted() {
    this.getDrawData();
  },
};
</script>

<style lang="scss">
.draw {
  &__slider {
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }

  &__uninitialized {
    font-size: 18px;
  }
}
</style>
